@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Quicksand', sans-serif;
}

h1 {
  margin: 15px;
}

.App-wrap {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

.soundcloud {
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Quicksand', sans-serif;
  font-weight: 100;
}

.soundcloud a {
  color: #cccccc;
  text-decoration: none;
}

section {
  background-color: black;
  color: white;
  padding: 0 15px;
}

.App-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

section article {
  color: white;
}

@media screen and (max-width:620px) {
  .App-logo {
    height: 50vmin;
  }

  .App-flex {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  section article {
    margin-bottom: 15px;
  }

  section article:first-of-type {
    margin-top: 15px;
  }

  .App-wrap {
    text-align: center;
  }
}
.App-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.App-header ul {
  padding: 0;
  margin: 20px 0;
}

.App-header ul li {
  list-style-type: none;
  display: inline-block;
  padding: 0 15px;
}

.App-header ul li:first-of-type:before {
  display: none;
}

.App-header ul li::before {
  content: "•";
  color: black;
  position: relative;
  left: -15px;
}
.App-footer {
  text-align: center;
  padding: 20px 0;
}
