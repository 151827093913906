@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Quicksand', sans-serif;
}

h1 {
  margin: 15px;
}

.App-wrap {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

.soundcloud {
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Quicksand', sans-serif;
  font-weight: 100;
}

.soundcloud a {
  color: #cccccc;
  text-decoration: none;
}

section {
  background-color: black;
  color: white;
  padding: 0 15px;
}

.App-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

section article {
  color: white;
}

@media screen and (max-width:620px) {
  .App-logo {
    height: 50vmin;
  }

  .App-flex {
    flex-direction: column;
  }

  section article {
    margin-bottom: 15px;
  }

  section article:first-of-type {
    margin-top: 15px;
  }

  .App-wrap {
    text-align: center;
  }
}